import {
  Component,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, interval, Observable } from 'rxjs';
import { DataService } from 'src/app/data.service';
import * as Papa from 'papaparse';

@Component({
  selector: 'app-display-ticket-blagnac',
  templateUrl: './display-ticket.component.html',
  styleUrls: ['./display-ticket.component.scss'],
})
export class DisplayTicketComponentBlagnac implements OnInit, OnDestroy {
  tickets: any = [];
  updatedTickets: any = [];
  ticketsAfter: any = [];
  @Input() item = 'TOUS';
  @Input() camera = '';
  @Input() problem = '';
  @Input() status = '';
  @Input() LaunchDisplayTicket = '';
  @Input() poste = '';

  @Output() problemeTousEvent = new EventEmitter<string>();
  @Output() problemeSnefEvent = new EventEmitter<string>();
  @Output() problemeSipproEvent = new EventEmitter<string>();
  @Output() problemeMairieEvent = new EventEmitter<string>();

  test: number = 0;
  i: number = 0;
  ticketForWarning: any = [];
  testif: boolean = false;
  colorText: string = '';
  problemeTous: any;
  problemeSnef: any;
  problemeSippro: any;
  problemeMairie: any;
  onlyCompteur: any = [];
  ticketCamera: any = [];
  problemeServer: string = 'no;';
  causePanne: string = '';
  private destroy$ = new Subject();

  data: any[];
  isDataLoaded = false; // Flag to track whether data is loaded

  constructor(private http: HttpClient, private dataService: DataService) {}

  getAllTickets() {
    console.log('poste', this.poste);
    this.problemeServer = '';
    console.log('updatedTickets 888 : ', this.updatedTickets);
    this.tickets = this.updatedTickets;
    this.onlyCompteur = [];
    var element = <HTMLInputElement>document.getElementById('disabled');
    var popUpDisabled = <HTMLInputElement>(
      document.getElementById('disablingTicket')
    );
    element.disabled = false;
    element.style.opacity = '1';
    popUpDisabled.style.display = 'none';
    this.problemeTous = this.tickets.length.toString();
    this.problemeSnef = this.tickets.filter(
      (ticket: any) => ticket.reporter == 'snef'
    );
    this.problemeSippro = this.tickets.filter(
      (ticket: any) => ticket.reporter == 'sippro'
    );
    this.problemeMairie = this.tickets.filter(
      (ticket: any) => ticket.reporter == 'mairie blagnac'
    );
    this.problemeTousEvent.emit(this.problemeTous);
    this.problemeSipproEvent.emit(this.problemeSippro.length.toString());
    this.problemeSnefEvent.emit(this.problemeSnef.length.toString());
    this.problemeMairieEvent.emit(this.problemeMairie.length.toString());

    this.testif = false;
    this.changeColor('lightsteelblue');

    if (this.camera == '') {
      if (this.problem == '') {
      } else {
        console.log('Only problem this time');
        this.tickets = this.tickets.filter(
          (ticket: any) => ticket.probleme == this.problem
        );
      }
      if (this.status == '') {
        console.log('This is empty etat');
      } else {
        console.log('etat running...');
        this.tickets = this.tickets.filter(
          (ticket: any) => ticket.status == this.status
        );
      }
    } else {
      this.ticketCamera = this.tickets.filter(
        (ticket: any) => ticket.camera == this.camera
      );
      if (this.problem == '') {
        this.tickets = this.tickets.filter(
          (ticket: any) => ticket.camera == this.camera
        );

        if (this.tickets.length > 0) {
          this.testif = true;
          this.changeColor('orange');
        } else {
          this.changeColor('lightsteelblue');
        }
      }
      //camera plus problème
      else {
        console.log('else camera plus problem');
        var ticketNoDisplayed = this.tickets.filter(
          (ticket: any) =>
            ticket.camera == this.camera && ticket.probleme == this.problem
        );
        console.log('ticketNoDisplayed', ticketNoDisplayed);
        this.tickets = this.tickets.filter(
          (ticket: any) => ticket.camera == this.camera
        );
        if (ticketNoDisplayed.length > 0) {
          this.testif = true;
          this.changeColor('red');
          element.disabled = true;
          element.style.opacity = '0.1';
          popUpDisabled.style.display = 'block';
        } else {
          if (this.ticketCamera.length > 0) {
            this.testif = true;
            this.changeColor('orange');
          } else {
            this.testif = false;
            this.changeColor('lightsteelblue');
          }
        }
      }
    }

    if (this.item == 'TOUS' || this.item == '') {
    } else {
      this.tickets.forEach((currentValue: any, index: number) => {
        this.tickets = this.tickets.filter(
          (ticket: any) => ticket.reporter == this.item
        );
      });
    }
    console.log('this.ticket : ', this.tickets);
    var j = 0;
    console.log('test : ', this.tickets);
    this.tickets.forEach((element: any) => {
      this.onlyCompteur[j] = element.compteur;
      j++;
    });
  }

  getValueUpdated() {
    this.displayloader();
    this.http
      .get('https://sippro-gmao.fr:8443/displayTicketsBlagnac')
      .subscribe(
        (data) => {
          this.updatedTickets = data;
          console.log('updatedTickets : ', this.updatedTickets);
          this.getAllTickets();
          this.hideloader();
        },
        (error) => {
          console.log('oops', error);
          this.problemeServer = 'yes';
          this.causePanne = error.message;
        }
      );
  }

  getValueTicketFilterAfterTest() {
    this.changeColor('lightsteelblue');
    this.problem = '';
    this.camera = '';
    this.getAllTickets();
    this.testif = false;
    this.changeColor('lightsteelblue');
  }

  setTicketsData(test: any) {
    this.ticketsAfter = test;
    console.log('this.ticketsAfter setted : ', this.ticketsAfter);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngOnChanges() {
    this.doSomething(this.camera);
  }

  doSomething(value: string) {
    console.log('this.item', value);
    this.getAllTickets();
  }

  displayDetails(compteur: number) {
    let i: number = 0;
    let details = document.getElementById('popup' + compteur);
    details!.style.display = 'block';
    details!.style.filter = 'blur(0px)';

    this.onlyCompteur.forEach((element: any) => {});

    /* for(i; i<500; i++)
    {
    console.log(i);
    try
    {
     let zoneBlur = document.getElementById('enableBlur' + i.toString());
            zoneBlur!.style.opacity = "0.1"
            zoneBlur!.style.pointerEvents = "none";
    }
    catch(e)
    {
      console.log(e)
    }

    }
*/
  }

  displayDetails2(compteur: number) {
    let i: number = 0;
    let details = document.getElementById('popup2' + compteur);
    details!.style.display = 'block';
    details!.style.filter = 'blur(0px)';

    this.onlyCompteur.forEach((element: any) => {
      //let zoneBlur = document.getElementById('enableBlur' + element.toString());
      //          zoneBlur!.style.opacity = "0.1";
      //          zoneBlur!.style.pointerEvents = "none";
    });

    /* for(i; i<500; i++)
      {
      console.log(i);
      try
      {
       let zoneBlur = document.getElementById('enableBlur' + i.toString());
              zoneBlur!.style.opacity = "0.1"
              zoneBlur!.style.pointerEvents = "none";
      }
      catch(e)
      {
        console.log(e)
      }

      }
  */
  }

  closePopup(compteur: number) {
    let i: number = 0;
    let details = document.getElementById('popup' + compteur);
    details!.style.display = 'none';
    this.onlyCompteur.forEach((element: any) => {
      //let zoneBlur = document.getElementById('enableBlur' + element.toString());
      // zoneBlur!.style.opacity = "1"
      //zoneBlur!.style.pointerEvents = "auto";
    });
  }

  closePopup2(compteur: number) {
    let i: number = 0;
    let details = document.getElementById('popup2' + compteur);
    details!.style.display = 'none';
    this.onlyCompteur.forEach((element: any) => {
      // let zoneBlur = document.getElementById('enableBlur' + element.toString());
      // zoneBlur!.style.opacity = "1"
      //zoneBlur!.style.pointerEvents = "auto";
    });
  }

  ngOnInit(): void {
    this.getData2();
    const source = interval(300000);
    source.subscribe((test) => {
      console.log('called');
      this.getValueUpdated();
    });
    this.getValueUpdated();
  }

  getData2() {
    this.dataService.getData2().subscribe((result: any) => {
      console.log('RESULTTTT....', result);
      this.data = result; // Assuming API response is an array
      this.isDataLoaded = true;
    });
  }

  downloadCSV() {
    if (this.isDataLoaded) {
      console.log('button clicked!');
      console.log('Data:', this.data);
      const csvData = this.convertToCSV(this.data);
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);

      // Create a link and trigger a download
      const a = document.createElement('a');
      a.href = url;
      a.download = 'data.csv';
      a.click();

      window.URL.revokeObjectURL(url);
      console.log('this button is active and Clicked!');
    } else {
      console.log('Data is not loaded yet');
    }
  }

  convertToCSV(data: any[]) {
    const csv = Papa.unparse(data);

    return csv;
  }

  changeColor(color: string) {
    let zoneBlur = document.getElementById('allTheCard');
    zoneBlur!.style.backgroundColor = color;
    let warningTitle = document.getElementById('titleWarning');
    warningTitle!.style.backgroundColor = color;
    this.colorText = color;
  }
  sendProblemSippro(value: string) {
    this.problemeSipproEvent.emit(value);
  }

  sendProblemSnef(value: string) {
    this.problemeSnefEvent.emit(value);
  }

  sendProblemMairie(value: string) {
    this.problemeMairieEvent.emit(value);
  }

  hideloader() {
    /*if(localStorage.getItem('role') == "operateur")
      {
                    let loadingOperator = document.getElementById('loading-maskTickets-operator');
                          loadingOperator!.style.display = "none";
      }
      else
      {*/
    let loadingAdmin = document.getElementById('loading-maskTickets');
    loadingAdmin!.style.display = 'none';
    //}
  }

  displayloader() {
    /*if(localStorage.getItem('role') == "operateur")
               {
                      let loadingOperator = document.getElementById('loading-maskTickets-operator');
                      loadingOperator!.style.display = "block";
               }
               else
               {*/
    let loadingAdmin = document.getElementById('loading-maskTickets');
    loadingAdmin!.style.display = 'block';
    // }
  }
}
